import React from "react";
import Layout from "../components/common/Layout";
import Meta from "../components/common/Meta";

const CookiesPolicy = () => {
  const meta = {
    title: "Cookies Policy｜（株）Digital Stacks",
    description: "Digital Stacks",
    pathname: "/cookies-policy/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <div>
        <div className="w-full mx-auto my-[72px] px-5 xl:px-[70px] ">
          <h2 className=" text-[28px] leading-[30px] font-bold text-dsc-title">
            Cookies Policy
          </h2>
          <div className="leading-[1.5] my-4 md:my-8">
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              This Cookies Policy describes the different types of cookies that
              (“Digital Stacks Corporation (DSC)”, “we”, “us” or “our”) uses on
              its website (https://en.corp.digitalstacks.net/) and the choices
              that users have.
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              Digital Stacks Corporation (DSC) (“we”, “us” or “our”) wants to
              ensure that your visit to our website is smooth, trusty and as
              useful to you as possible. For this reason, we want to use cookies
              to make our website relevant to your interests and needs.
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              初めて当社の Web サイトにアクセスすると、この Cookie
              ポリシーに従って Cookie
              の使用に同意するよう求められます。同意すると、引き続きデバイスに保存されます。
            </p>
          </div>
          <div className="leading-[1.5] my-4 md:my-8">
            <h2 className="my-0 md:my-2 mt-8 text-[28px] text-dsc-title leading-[30px] font-bold mb-4">
              クッキーとは？
            </h2>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              当社のウェブサイトにアクセスすると、情報が「Cookie」の形でデバイスに保存されます。
              Cookie
              は、デバイスに保存される小さなファイルで、特定の設定とデータを保存して、ブラウザー経由で当社の
              Web サイトと交換します。
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              原則として、明示的な許可がない限り、Cookie
              を介して個人データを収集することはありません。
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              デバイスを認識させたくない場合は、デバイスからすべての Cookie
              を削除するか、すべての Cookie をブロックするか、Cookie
              が保存される前に警告を受け取るようにブラウザを設定してください。これを行う方法の簡単な説明を以下に示します。
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              Cookie がないと、当社の Web
              サイトの特定の機能が機能しなくなったり、正しく機能しなくなったりする可能性があることに注意してください。
            </p>
          </div>
          <div className="leading-[1.5] my-4 md:my-8">
            <h2 className="my-0 md:my-2 mt-8 text-[28px] text-dsc-title leading-[30px] font-bold mb-4">
              どのような種類の COOKIE を使用していますか?
            </h2>
            <h3 className="mt-4 my-2 text-[22px] leading-[34px]  font-bold text-dsc-body">
              Strictly necessary cookies
            </h3>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              これらの Cookie
              は、ウェブサイトが機能するために必要であり、システムでオフにすることはできません。これらは通常、プライバシー設定の設定、ログイン、フォームへの入力など、サービスの要求に相当するユーザーのアクションに応じてのみ設定されます。これらの
              Cookie
              が保存される前に、ブラウザをブロックまたは警告を受け取るように設定できますが、その場合、当社の
              Web サイトの一部が正しく機能しない場合があります。
            </p>
            <h3 className="mt-4 my-2 text-[22px] leading-[34px]  font-bold text-dsc-body">
              Functional cookies
            </h3>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              これらの Cookie により、当社の Web
              サイトの機能が強化され、カスタマイズされたビデオや画像が表示されます。それらは、当社または当社のウェブサイトにサービスを追加したサードパーティプロバイダーによって設定される場合があります。これらの
              Cookie
              がないと、これらのサービスの一部またはすべてが正しく機能しない場合があります。
            </p>
            <h3 className="mt-4 my-2 text-[22px] leading-[34px]  font-bold text-dsc-body">
              Performance/Analytics Cookies
            </h3>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              これらの Cookie を使用すると、訪問数とトラフィック
              ソースをカウントできるため、ウェブサイトのパフォーマンスを測定および改善できます。それらは、最も人気のあるページと最も人気のないページを理解し、訪問者が当社の
              Web
              サイト内をどのように移動するかを理解するのに役立ちます。これらの
              Cookie がないと、お客様がいつ当社の Web
              サイトにアクセスしたかがわからず、そのパフォーマンスを監視することもできません。
            </p>
            <h3 className="mt-4 my-2 text-[22px] leading-[34px]  font-bold text-dsc-body">
              Targeting Cookies
            </h3>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              これらの Cookie は、当社の広告パートナーによって当社の Web
              サイトを通じて設定される場合があります。これらの企業は、お客様の興味のプロファイルを作成し、お客様に関連する広告を表示するために使用する場合があります。それらは、ブラウザとデバイスを一意に識別することに基づいています。これらの
              Cookie がないと、ターゲットを絞った広告が表示されなくなります。
            </p>
            <h3 className="mt-4 my-2 text-[22px] leading-[34px]  font-bold text-dsc-body">
              How to control cookies
            </h3>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              ほとんどのブラウザは、デフォルトで Cookie
              を受け入れるように設定されています。必要に応じて、Cookie
              を無効にするか、Cookie
              が保存されているときに通知するようにブラウザを設定できます。
              Cookie をブロックするか、Cookie を配置するための当社の Web
              サイトの要求を受け入れることを拒否した場合、当社の Web
              サイトの一部が正しく機能しないか、まったく機能しない可能性があります。
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              次のリンクにアクセスすると、Web ブラウザのヘルプ ページで Cookie
              の管理に関する詳細情報を見つけることができます。
            </p>
            <ul className="pl-8 md:pl-12 text-dsc-link underline list-disc">
              <li>
                <a
                  href="https://support.google.com/chrome/answer/95647"
                  target="_blank"
                  rel="noreferrer"
                >
                  グーグルクローム
                </a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                  target="_blank"
                  rel="noreferrer"
                >
                  モジラ ファイアフォックス
                </a>
              </li>
              <li>
                <a
                  href="https://support.microsoft.com/en-us/help/4027947/windows-delete-cookies"
                  target="_blank"
                  rel="noreferrer"
                >
                  マイクロソフトエッジ
                </a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/11.0/mac/10.13"
                  target="_blank"
                  rel="noreferrer"
                >
                  サファリ (macOS)
                </a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/en-us/HT201265"
                  target="_blank"
                  rel="noreferrer"
                >
                  サファリ (iOS)
                </a>
              </li>
            </ul>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              その他のブラウザーについては、ブラウザーの製造元が提供するドキュメントを参照してください。
            </p>
            <h3 className="mt-4 my-2 text-[22px] leading-[34px]  font-bold text-dsc-body">
              連絡先
            </h3>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              If you have any queries in relation to this notice or our use of
              cookies, please contact us at: 〒141-0001 東京都品川区北品川5-5-15
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              ‍大崎ブライトコア4階SHIP
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              Osaki Bright Core 4F SHIP
            </p>
            <p className="text-dsc-body my-2 text-[16px] leading-[25px]">
              5-5-15, Kita-Shinagawa, Shinagawa-ku, Tokyo 141-0001 Japan
            </p>
            <p className="text-dsc-body [&_a]:text-dsc-link [&_a:hover]:text-dsc-button [&_a]:underline">
              Email:
              <a href="mailto:​​info@digitalstacks.net">
                info@digitalstacks.net
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CookiesPolicy;
